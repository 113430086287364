// extracted by mini-css-extract-plugin
export var sprite3DIcons = "cM_tX";
export var sprite3DIcons__sprite = "cM_tY";
export var sprite3DIcons__sprite3DIcon_1 = "cM_tZ";
export var sprite3DIcons__sprite3DIcon_10 = "cM_t8";
export var sprite3DIcons__sprite3DIcon_11 = "cM_t9";
export var sprite3DIcons__sprite3DIcon_12 = "cM_vb";
export var sprite3DIcons__sprite3DIcon_13 = "cM_vc";
export var sprite3DIcons__sprite3DIcon_14 = "cM_vd";
export var sprite3DIcons__sprite3DIcon_15 = "cM_vf";
export var sprite3DIcons__sprite3DIcon_16 = "cM_vg";
export var sprite3DIcons__sprite3DIcon_17 = "cM_vh";
export var sprite3DIcons__sprite3DIcon_18 = "cM_vj";
export var sprite3DIcons__sprite3DIcon_19 = "cM_vk";
export var sprite3DIcons__sprite3DIcon_2 = "cM_t0";
export var sprite3DIcons__sprite3DIcon_20 = "cM_vl";
export var sprite3DIcons__sprite3DIcon_21 = "cM_vm";
export var sprite3DIcons__sprite3DIcon_22 = "cM_vn";
export var sprite3DIcons__sprite3DIcon_3 = "cM_t1";
export var sprite3DIcons__sprite3DIcon_4 = "cM_t2";
export var sprite3DIcons__sprite3DIcon_5 = "cM_t3";
export var sprite3DIcons__sprite3DIcon_6 = "cM_t4";
export var sprite3DIcons__sprite3DIcon_7 = "cM_t5";
export var sprite3DIcons__sprite3DIcon_8 = "cM_t6";
export var sprite3DIcons__sprite3DIcon_9 = "cM_t7";